export default defineNuxtRouteMiddleware(async (to) => {
  const notificationsStore = useNotificationsStore()
  const userStore = useUserStore()
  const dataLayer = useDataLayer()
  const nuxtApp = useNuxtApp()

  const sendAccessGrantedReceiptEmail = async (
    userId: number,
    receiptPapers: string
  ) => {
    try {
      await nuxtApp.$api.user.sendAccessGrantedReceiptEmail(
        userId,
        receiptPapers
      )
    } catch (e) {
      const error = new Error('Could not send access granted receipt', {
        cause: e,
      })
      console.error(error)
      if (import.meta.client) {
        nuxtApp.$sentry.captureException(error)
      }
      notificationsStore.add({
        translationKey: 'user.errors.CouldNotSendAccesGrantedReceipt',
        type: 'error',
      })
    }
  }

  // Handle confirmemailtoken used after confirming your email address in the trial signup flow. Only run on initial client load.
  const isInitialClientLoad =
    import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered

  const confirmationToken = to.query.confirmemailtoken
  const isIdentity = to.query.identity === 'true'
  const userGuid = to.query.guid
  const receiptPapers = to.query.receiptPapers

  if (
    isInitialClientLoad &&
    confirmationToken &&
    typeof confirmationToken === 'string'
  ) {
    if (isIdentity && typeof userGuid === 'string') {
      try {
        await nuxtApp.$api.user.confirmEmailJwt(userGuid, confirmationToken)
        await userStore.refreshUser()

        dataLayer.trial.activateUser()
        notificationsStore.add({
          translationKey: 'EmailConfirmed',
          type: 'success',
        })

        if (userStore.user && typeof receiptPapers === 'string') {
          await sendAccessGrantedReceiptEmail(
            userStore.user.userId,
            receiptPapers
          )
        } else {
          const errorMsg =
            'Missing receiptPapers query parameter, so no access granted receipt was sent'

          console.error(errorMsg)
          if (import.meta.client) {
            nuxtApp.$sentry.captureException(new Error(errorMsg))
          }
        }
      } catch (e) {
        const error = new Error('Error confirming email', {
          cause: e,
        })
        console.error(error)
        if (import.meta.client) {
          nuxtApp.$sentry.captureException(error)
        }
        return notificationsStore.add({
          translationKey: 'user.errors.EmailTokenDoesNotExist',
          type: 'error',
        })
      }
    } else {
      try {
        // TODO this will break when or if the PATCH CALL do not return the user object.
        const user = await nuxtApp.$api.user.confirmEmail(confirmationToken)
        await userStore.loadUserFromAutologin({
          autologin: user.autoLogin,
        })

        dataLayer.trial.activateUser()
        notificationsStore.add({
          translationKey: 'EmailConfirmed',
          type: 'success',
        })

        if (userStore.user && typeof receiptPapers === 'string') {
          await sendAccessGrantedReceiptEmail(
            userStore.user.userId,
            receiptPapers
          )
        } else {
          const errorMsg =
            'Missing receiptPapers query parameter, so no access granted receipt was sent'

          console.error(errorMsg)
          if (import.meta.client) {
            nuxtApp.$sentry.captureException(new Error(errorMsg))
          }
        }
      } catch (e) {
        const error = new Error('Error confirming email', {
          cause: e,
        })
        console.error(error)
        if (import.meta.client) {
          nuxtApp.$sentry.captureException(error)
        }
        return notificationsStore.add({
          translationKey: 'user.errors.EmailTokenDoesNotExist',
          type: 'error',
        })
      }
    }
  }
  const ssoResult = to.query?.SSOResult?.toString()
  // Handle SSOResult used after logging in with SSO
  if (ssoResult) {
    // First time login
    if (ssoResult === 'Success') {
      notificationsStore.add({
        translationKey: 'WelcomeTextSSO',
        type: 'success',
        showSubscribeButton: true,
      })
      return
    } else if (ssoResult === 'SuccessSSOUserAlreadyExists') {
      // Logged in before, no need to show a modal
      return
    } else if (ssoResult === 'EmailNotSSODomainClient') {
      // Logged in with wrong SSO Domain
      notificationsStore.add({
        translationKey: 'sso.NotSSOEmail',
        type: 'error',
      })
      return
    }
    notificationsStore.add({
      translationKey: 'unknownError',
      type: 'error',
    })
  }
})
