import { parse } from 'cookie-es'
import type { MinimalUser } from '~/api/account'
import type { OldUser } from '~/api/user'

import type { ArticleUserDTO } from '~/typesAuto/apicore/v1'

export type LoginResponse = {
  status: 'success' | 'error'
  message?: string
  code?: number
  error?: unknown
}

export type TempUser = {
  // statemanagement
  loadedAccessToken?: string

  // basic
  userId: number
  userGuid?: string
  name: string
  email: string
  workEmailConfirmed: boolean
  hasActiveSubscription: boolean
  hasActiveNonTrialSubscription: boolean
  papers: number[]
  isAdmin: boolean

  // organistation
  clientId?: number
  jobPlace?: string

  // mit altinget
  educationId?: number | null
  userBranchId?: number | null
  userTitleId?: number | null
  countryId?: number | null
  birthyear?: string
  zipcode?: string
  contactPhone: string

  // extra
  avatar?: string
  readingList: ArticleUserDTO[]

  // deprecated
  loginType: 'IP' | 'newuser' | 'SSO'
  legacyAutoToken: string
  contactEmail?: string
}

function mergeToTempUser(
  newUser: MinimalUser,
  oldUser: OldUser,
  readingList: ArticleUserDTO[]
): TempUser {
  const newTempUser: TempUser = {
    loadedAccessToken: undefined,
    userId: newUser.id ?? 0,
    name: newUser.name ?? '',
    email: newUser.workEmail,
    workEmailConfirmed: oldUser.workEmailConfirmed
      ? Boolean(oldUser.workEmailConfirmed)
      : false,
    legacyAutoToken: newUser.autoLogin ?? '',
    readingList: readingList,
    avatar: newUser.avatar ?? undefined,
    isAdmin: oldUser.isAdmin,
    loginType: oldUser.loginType,
    birthyear: newUser.birthyear?.toString() ?? '',
    contactPhone: newUser.contactPhone ?? '',
    contactEmail: newUser.contactEmail ?? '',
    jobPlace: newUser.client?.jobPlace,
    zipcode: newUser.zipcode ?? '',
    hasActiveSubscription: oldUser.hasActiveSubscription,
    hasActiveNonTrialSubscription: oldUser.hasActiveNonTrialSubscription,
    papers: oldUser.papers,
    clientId: newUser.client?.id ?? oldUser.clientId,

    educationId: newUser.educationId,
    userBranchId: newUser.userBranchId,
    userTitleId: newUser.userTitleId,
    countryId: newUser.countryId,
  }

  return newTempUser
}

export const useUserStore = defineStore('user', () => {
  const nuxtApp = useNuxtApp()
  const config = useRuntimeConfig()
  const event = useRequestEvent()
  const requestHeaders = useRequestHeaders()

  const autologinCookie = useCookie('autologin', {
    path: '/',
    sameSite: 'lax',
    maxAge: 60 * 60 * 24 * 365,
  })

  const ssoCookieString = useCookie<string>('.AspNetCore.Cookies', {
    readonly: true,
  })

  const disableIpLoginCookie = useCookie<boolean>('disableIPlogin')

  const accessToken = useAccessToken()
  const autoLogin = useLegacyAutoLogin()

  /*
  // State
  */
  const user = ref<TempUser | null>(null)

  /*
  // Getters
  */
  const loadedAccessToken = computed(() => user.value?.loadedAccessToken)

  const isLoggedIn = computed(() => Boolean(user.value))

  const hasSubscription = (paperId: number) => {
    return user.value?.papers.includes(paperId)
  }

  const hasClient = computed(() => user.value?.clientId ?? 0 > 0)

  const hasName = computed(() => !!user.value?.name)

  const hasImage = computed(() => {
    return user.value?.avatar && user.value.avatar !== 'avatar_grey.svg'
  })

  const hasConfirmedWorkEmail = computed(() => !!user.value?.workEmailConfirmed)

  const emailBelongsToAlrow = computed(() => {
    return /@(mm\.dk|(altinget\.(dk|se|no)))$/g.test(user.value?.email ?? '')
  })

  const id = computed(() => user.value?.userId)

  /*
  // Actions
  */
  const loadUserFromAccessToken = async () => {
    if (
      !accessToken.token.value ||
      (user.value && user.value.loadedAccessToken === accessToken.token.value)
    ) {
      return
    }

    const userPayload = accessToken.payload.value
    if (!userPayload) {
      return
    }

    if (!userPayload.userId) {
      console.error('payload:', userPayload)
      console.error('payload userId not found login failed')
      return
    }

    const userData = await nuxtApp.$api.account.getUser(userPayload.userId)
    const readingList = await nuxtApp.$api.user.getReadingList(
      userPayload.userId
    )

    user.value = {
      // statemanagement
      loadedAccessToken: accessToken.token.value,
      // basic
      userId: userPayload.userId,
      userGuid: userPayload.userGuid,
      name: userData.name ?? userPayload['name'],
      email: userPayload['name'],
      workEmailConfirmed: userPayload.isEmailConfirmed,
      hasActiveSubscription: userPayload.hasActiveSubscription,
      hasActiveNonTrialSubscription: userPayload.hasActiveNonTrialSubscription,
      papers: userPayload.paperIds ?? [],
      isAdmin: userPayload.isAdmin,

      // organistation
      clientId: userData.client?.id,
      jobPlace: userData.client?.jobPlace,

      // mit altinget
      educationId: userData.educationId,
      userBranchId: userData.userBranchId,
      userTitleId: userData.userTitleId,
      countryId: userData.countryId,

      birthyear: userData.birthyear?.toString() ?? '',
      zipcode: userData.zipcode ?? '',
      contactPhone: userData.contactPhone ?? '',

      // extra
      avatar: userData.avatar ?? undefined,
      readingList: readingList,

      // deprecated
      legacyAutoToken: userData.autoLogin, // used by legacy pages decisionChain iframe.
      loginType: 'newuser',
    } as TempUser

    return user.value
  }

  const loadUserFromAutologin = async ({
    autologin,
  }: {
    autologin: string
  }) => {
    let oldUser = {} as OldUser
    if (ssoCookieString.value) {
      oldUser = await nuxtApp.$api.user.ssoLogin()
    } else {
      try {
        oldUser = await nuxtApp.$api.user.autologin(autologin)
      } catch (error) {
        const typedError = error as { response?: { status?: number } }

        if (typedError.response?.status !== 410) {
          // Don't handle non-410 errors here
          throw error
        }

        await migrateTokens()
        return await loadUserFromAccessToken()
      }
    }

    let userData = {} as MinimalUser
    let readingList: ArticleUserDTO[] = []
    if (oldUser.userId > 0 && oldUser.autologin) {
      userData = await nuxtApp.$api.account.getUser(oldUser.userId)
      readingList = await nuxtApp.$api.user.getReadingList(oldUser.userId)
    } else {
      // Its an IP login. Grab the name from oldUser and overwrite workEmailConfirmed
      userData.name = oldUser.name
      userData.workEmailConfirmed = 1
      userData.autoLogin = oldUser.autologin
      userData.client = {
        id: oldUser.clientId,
        jobPlace: oldUser.jobPlace ?? '',
        zipcode: '',
      }
    }

    user.value = mergeToTempUser(userData, oldUser, readingList)
    return user.value
  }

  const refreshUser = async () => {
    if (accessToken.token.value) {
      const refreshResponse = await accessToken.refresh()
      if (refreshResponse.result === 'Refresh failed') {
        await logout()
        return
      }

      await loadUserFromAccessToken()
    } else {
      try {
        const oldUser = await nuxtApp.$api.user.autologin(
          user.value?.legacyAutoToken ?? ''
        )
        setUserCookies(oldUser.autologin ?? '')

        const userData = await nuxtApp.$api.account.getUser(oldUser.userId)
        const readingList = await nuxtApp.$api.user.getReadingList(
          oldUser.userId
        )

        user.value = mergeToTempUser(userData, oldUser, readingList)
      } catch (error) {
        console.error('LoginUser failed with error:', error)
      }
    }
  }

  const loginUserJwt = async (username: string, password: string) => {
    try {
      const response = await $fetch<LoginResponse>('/api/auth/sign-in', {
        method: 'POST',
        body: {
          username,
          password,
        },
      })
      refreshCookie('AccessToken')

      if (response.status === 'success') {
        await loadUserFromAccessToken()
      }

      return response
    } catch (error) {
      const typedError = error as { response?: { status?: number } }

      const response: LoginResponse = {
        status: 'error',
        code: typedError.response?.status,
        error: typedError,
      }

      return response
    }
  }

  const loginUser = async (username: string, password: string) => {
    try {
      await $fetch('/api/auth/old/sign-in', {
        method: 'POST',
        body: {
          username,
          password,
        },
      })
    } catch (error) {
      const typedError = error as { response?: { status?: number } }

      if (typedError.response?.status !== 410) {
        // Don't handle non-410 errors here

        const response: LoginResponse = {
          status: 'error',
          code: typedError.response?.status,
          error: typedError,
        }
        return response
      }

      return loginUserJwt(username, password)
    }

    refreshCookie('autologin')

    if (autoLogin.token.value) {
      loadUserFromAutologin({ autologin: autoLogin.token.value })
    }

    const response: LoginResponse = { status: 'success', code: 200 }
    return response
  }

  const loginIp = async () => {
    try {
      await $fetch<LoginResponse>('/api/auth/old/sign-in-ip', {
        method: 'POST',
        retry: 0,
        headers: import.meta.server
          ? useRequestHeaders(['cf-connecting-ip'])
          : undefined,
        onResponse: ({ response }) => {
          if (!import.meta.server || !event) return

          passCookiesToClient(event, response)

          // Save the new access token to the context so it can be used in the current SSR response
          const cookies = parse(response.headers.get('set-cookie') || '')
          event.context.newAutoLogin = cookies.autologin
        },
      })

      refreshCookie('autologin')

      if (autoLogin.token.value) {
        loadUserFromAutologin({ autologin: autoLogin.token.value })
      }
    } catch (error) {
      console.error('LoginIp failed with error:', error)
      return null
    }
  }

  const loginSSO = async () => {
    try {
      await $fetch<LoginResponse>('/api/auth/old/sign-in-sso', {
        method: 'POST',
        credentials: import.meta.server ? undefined : 'include', // Include cookies in the request on the client
        retry: 0,
        headers: import.meta.server ? useRequestHeaders(['cookie']) : undefined,
        onResponse: ({ response }) => {
          if (!import.meta.server || !event) return

          passCookiesToClient(event, response)

          // Save the new access token to the context so it can be used in the current SSR response
          const cookies = parse(response.headers.get('set-cookie') || '')
          event.context.newAutoLogin = cookies.autologin
        },
      })

      refreshCookie('autologin')

      if (autoLogin.token.value) {
        loadUserFromAutologin({ autologin: autoLogin.token.value })
      }
    } catch (error) {
      console.error('LoginSSO failed with error:', error)
      return null
    }
  }

  const setUserCookies = (cookie: string) => {
    try {
      autologinCookie.value = cookie
      refreshCookie('autologin')
    } catch (e) {
      console.error('Got an error in setUserCookies. The error was:\n', e)
      throw e
    }
  }

  const logout = async () => {
    const loginType = user.value?.loginType

    await accessToken.signOut()

    user.value = null
    disableIpLoginCookie.value = true

    if (loginType === 'SSO') {
      await navigateTo(
        `https://${config.public.site.apicoreurl}/ssoindex/signout`,
        { external: true }
      )
    }
  }

  /**
   * Exchange the old autologin token for a new access token and refresh token
   */
  const migrateTokens = async () => {
    await $fetch<LoginResponse>('/api/auth/old/migrate', {
      method: 'POST',
      credentials: import.meta.server ? undefined : 'include', // Include cookies in the request on the client
      retry: 0,
      headers: import.meta.server
        ? { cookie: requestHeaders?.cookie }
        : undefined,
      onResponse: ({ response }) => {
        if (!import.meta.server || !event) return

        passCookiesToClient(event, response)

        // Save the new access token to the context so it can be used in the current SSR response
        const cookies = parse(response.headers.get('set-cookie') || '')
        event.context.newAccessToken = cookies.AccessToken
        event.context.newAutoLogin = undefined
      },
    })

    refreshCookie('AccessToken')
    refreshCookie('autologin')
  }

  return {
    user,
    loadedAccessToken,
    isLoggedIn,
    hasSubscription,
    hasClient,
    hasName,
    hasImage,
    hasConfirmedWorkEmail,
    emailBelongsToAlrow,
    id,
    loadUserFromAccessToken,
    loadUserFromAutologin,
    refreshUser,
    loginUserJwt,
    loginUser,
    loginIp,
    loginSSO,
    setUserCookies,
    logout,
  }
})
