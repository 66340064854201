export default defineNuxtPlugin(async () => {
  const userStore = useUserStore()
  const accessToken = useAccessToken()
  const legacyAutoLogin = useLegacyAutoLogin()

  const ssoCookieString = useCookie<string>('.AspNetCore.Cookies')
  const disableIPlogin = useCookie<boolean>('disableIPlogin')

  if (userStore.isLoggedIn) {
    return
  }

  try {
    if (accessToken.token.value) {
      if (!accessToken.isValid()) {
        const refreshResponse = await accessToken.refresh()
        if (refreshResponse.result === 'Refresh failed') {
          await userStore.logout()
          return
        }
      }

      await userStore.loadUserFromAccessToken()
      return
    }

    // Delete access token cookie if audience is wrong
    if (accessToken.unvalidatedToken.value) {
      await accessToken.signOut()
    }

    if (ssoCookieString.value) {
      await userStore.loginSSO()
      return
    }

    if (legacyAutoLogin.token.value) {
      await userStore
        .loadUserFromAutologin({ autologin: legacyAutoLogin.token.value })
        .catch(async (error) => {
          if (error.response && error.response.status === 401) {
            // clear the cookie if the autologin fails
            await userStore.logout()
          } else {
            throw error
          }
        })
      return
    }

    if (!disableIPlogin.value) {
      await userStore.loginIp()
      return
    }
  } catch (error) {
    console.error('Error in auth.ts:\n', error)
  }
})
