export default defineNuxtRouteMiddleware(async () => {
  const accessToken = useAccessToken()
  if (accessToken.token.value === undefined) {
    return
  }

  const userStore = useUserStore()

  try {
    if (!accessToken.isValid()) {
      const refreshResponse = await accessToken.refresh()
      if (refreshResponse.result === 'Refresh failed') {
        await userStore.logout()
        return
      }
    }

    await userStore.loadUserFromAccessToken()
  } catch (error) {
    console.error('Error in 01.authRefreshCookie.global.ts:\n', error)
  }
})
